/* Footer.css */
.footer-container {
  background-color: #000;
  padding: 2rem;
  color: #fff;
  font-family: Arial, sans-serif;
}

.footer-logo img {
  width: 50px;
}

.footer-links {
  display: flex;
  flex-direction: column; /* Change to a column layout for mobile screens */
  gap: 1rem;
  align-items: flex-start; /* Align items to the start for mobile layout */
}

.route-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem; /* Adjust font size for mobile screens */
  transition: color 0.3s ease;
}

.social-media-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem; /* Adjust font size for mobile screens */
  display: flex;
  gap: 0.5rem;
  transition: color 0.3s ease;
}

.footer-address {
  margin-top: 1rem; /* Add margin for separation */
}

.footer-address p {
  margin: 0.25rem 0; /* Adjust margin for mobile layout */
  font-size: 0.9rem; /* Adjust font size for mobile screens */
}

.footer-copyright {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem; /* Adjust font size for mobile screens */
}

.route-links {
  display: flex;
  gap: 1rem; /* Increase the gap to add space between routes */
}

/* Add media query for mobile responsiveness */
@media screen and (max-width: 768px) {
  .footer-links {
    align-items: center; /* Revert alignment for larger screens */
  }

  .route-links {
    flex-direction: column; /* Stack route links in a column on smaller screens */
    align-items: center;
    gap: 0.5rem;
  }
}
