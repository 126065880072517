/* OurServices.css */
.our-services-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
  }
  
  .intro {
    margin-bottom: 4rem;
    text-align: center;
  }
  
  .services {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .service-container {
    flex: 1 1 300px;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 6px #f6c90e;
    background-color: #fff;
    transition: transform 0.3s ease;
    text-align: center;
  }
  
  .service-container:hover {
    transform: translateY(-5px);
  }
  
  .intro h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight:bolder;
    color: #f6c90e;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
  }
  
  .services h3 {
    font-size: 2rem;
    font-weight:bolder;
    color: #f6c90e;
    font-family:Verdana, Geneva, Tahoma, sans-serif; /* Change the color to a professional blue */
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  