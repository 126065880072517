
.contact-container {
    background-color: #f9f9f9;
    padding: 3rem;
    text-align: center;
    color: #333;
  }
  
  .contact-container h1{
    font-size: 3.5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
  }
  .contact-info {
    margin-top: 2rem;
  }
  
  .whatsapp,
  .email {
    font-size: 2.5rem;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    margin-right: 0.5rem;
    font-size: 2rem;
  }
  
  .whatsapp a,
  .email a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .whatsapp a:hover,
  .email a:hover {
    color: #0056b3;
  }
  
  /* Animation */
  .contact-container {
    animation: fade-in 1s ease-in-out;
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    resize: vertical;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Animation */
  .contact-container {
    animation: fade-in 1s ease-in-out;
  }
  /* ... (rest of the animation code) */
  