/* Home.css */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  min-height: 100vh; /* Ensure the container takes at least the full height of the viewport */
}

.logo-image {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.banner {
  position: relative;
  text-align: center;
  padding: 50px 0;
  color: #090909;
  z-index: 1; /* Ensure the text is on top of the background image */
  width: 100vw; /* Set the width to fit the entire viewport */
}

.banner::before {
  content: '';
  background-image: url('./bannerimg.jpeg');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: -1; /* Place the background behind the content */
}

.banner h1 {
  font-size: 40px;
  font-weight:bolder;
  margin-bottom: 20px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.banner p {
  font-size: 25px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin: 20px;
}

.content {
  position: relative;
  text-align: center;
  color: #060606;
  margin: 20px 0;
  z-index: 1; /* Ensure the text is on top of the background image */
  width: 100vw; /* Set the width to fit the entire viewport */
}

.content::before {
  content: '';
  background-image: url('./contentimg.jpeg');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: -1; /* Place the background behind the content */
}

.content h2 {
  font-size: 50px;
  font-weight:bolder;
  margin: 20px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.content p {
  font-size: 20px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-right: 50px;
}

.partner-section {
  position: relative;
  text-align: center;
  color: #030303;
  margin-bottom: 50px;
  z-index: 1; /* Ensure the text is on top of the background image */
  width: 100vw; /* Set the width to fit the entire viewport */
}

.partner-section::before {
  content: '';
  
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: -1; /* Place the background behind the content */
}

.partner-section h2 {
  font-size: 60px;
  font-weight:bolder;
  margin-bottom: 20px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.partner-logo-image {
  width: 350px;
  height: auto;
}

/* Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeInUp 2s ease;
}
