/* Navbar.css */
.navbar {
    background-color: #f6c90e;
    color: #000;
    padding: 1.5rem 1rem;
  }
  
  .logo {
    font-size: 2.5rem;
    font-weight: bolder;
    text-decoration: none;
    color: #000;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bolder;
    margin-left: 5px;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  
  .nav-item {
    text-decoration: none;
    color: #000;
    font-weight: bold;
    margin-left: 2.5rem;
    transition: color 0.3s ease;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bolder;
  }
  
  .nav-item:hover {
    color: #000;
  }
  
  /* Update all text to black and bold */
  .navbar,
  .navbar .logo,
  .navbar .nav-item {
    color: #000;
    font-weight: bold;
  }
  
  /* Update text color on hover */
  .navbar .nav-item:hover {
    color: #333;
  }
  