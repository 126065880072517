/* Members.css */
.core-team-heading {
  font-size: 3rem; /* Adjust font size for better mobile view */
  font-weight: bold;
  color: #f6c90e;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center; /* Add margin at the bottom of the heading */
  margin-top: 50px;
}

.members-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2rem;
}

.member {
  width: 350px; /* Increase the card width */
  text-align: center;
  margin: 1rem;
  padding: 1.5rem;
  border: 1px solid #f6c90e;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.member-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  overflow: hidden; /* Change to "hidden" for better circle cropping effect */
  border-radius: 100%;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Change to "cover" for better circle cropping effect */
}

.member h3 {
  color: #333;
  font-size: 2rem; /* Increase the font size */
  margin-bottom: 1rem;
}

.member p {
  color: #555;
  font-size: 1.6rem; /* Increase the font size */
}
