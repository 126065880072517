/* AboutUs.css */
.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.section {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column; /* Change to a column layout for better mobile responsiveness */
  align-items: center;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px #f6c90e;
  background-color: #fff;
  animation: fadeInUp 1s ease;
  transition: transform 0.2s ease;
}

.section:hover {
  transform: scale(1.02); /* Add a slight scale-up effect on hover */
}

.section-left,
.section-right {
  padding: 1rem; /* Add padding for better mobile layout */
}

.section-heading {
  font-size: 2rem; /* Adjust font size for better mobile view */
  font-weight: bold;
  color: #f6c90e;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center; /* Center the heading text */
}

.section-description {
  font-size: 1rem; /* Adjust font size for better mobile view */
  line-height: 1.6;
  color: #333;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center; /* Center the description text */
}

/* Add media queries for responsiveness */
@media screen and (max-width: 768px) {
  .section {
    padding: 1rem; /* Adjust padding for better mobile layout */
  }
}
